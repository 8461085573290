/**
 * instantinateContactForm
 * 
 * This function creates a closure to manage the state of the contact form, and sending ajax requests.
 * @returns {Object} Returns the contactForm function
 */
function instantinateContactForm() {

    /**
     * contactFormCallback
     * 
     * @param {String} recaptchaResponse The google recaptcha response that is automatically passed to this callback function.
     * @returns {Object} Returns an object with either a "success" key, or "error" key containing a string to be output to the user.
     */
    function contactFormCallback(recaptchaResponse) {

        // Only proceed if recaptcha was successful
        if (recaptchaResponse !== grecaptcha.getResponse()) {
            // Return error response if contactFormCallback() is being run manually, and not a callback by recaptcha.
            return { "error": "reCaptcha invalid" };
        }

        // Remove any existing contact form notices from the DOM
        clearContactFormNotice();

        // Select form elements to target their values
        var name = document.querySelector('[name=contact-form-input-name]');
        var email = document.querySelector('[name=contact-form-input-email]');
        var message = document.querySelector('[name=contact-form-textarea-message]');

        // Send POST request via axios
        axios({
            method: 'POST',
            url: '/ajax/contact',
            data: {
                "name": name.value,
                "email": email.value,
                "message": message.value,
                "recaptchaResponse": grecaptcha.getResponse()
            }
        })
            .then(function (response) {
                console.log('front end response');
                console.log(response.data);
                // Select the form wrapper
                var form = document.getElementById('contactForm');

                // Create response notice markup
                var contactFormNotice = Handlebars.templates.contactFormNotice(response.data);

                // Add it to the DOM
                form.insertAdjacentHTML('afterbegin', contactFormNotice);

                // Reset form
                if (response.data.success) {
                    // Clear/reset all form fields
                    form.reset();

                    // Reset reCaptcha
                    grecaptcha.reset();
                }

            })
            .catch(function (error) {
                console.log('front end error');
                console.log(error);
            });

    }

    /**
     * clearContactFormNotice
     * 
     * Removes any form notices from the DOM.
     * @returns void
     */
    function clearContactFormNotice() {

        // Select all notices in the dom
        var allContactFormNotices = document.querySelectorAll('.contact-form-notice');

        // Loop through all of them, and remove them from the DOM.
        for (var i = 0; i < allContactFormNotices.length; i++) {
            allContactFormNotices[i].parentNode.removeChild(allContactFormNotices[i]);
        }

    }

    return contactFormCallback;

}

var mi_contactForm = instantinateContactForm();
